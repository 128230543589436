import { modalShadow } from 'app/styled/GlobalStyles'
import { InputElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

export const StyledOption = styled.div`
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  font-weight: 600;
  font-size: 12px;
  color: var(--color-text-3);
  &:hover {
    background-color: var(--color-bg-3);
    border-radius: 4px;
  }
`

export const OptionsWrapper = styled.div`
  max-height: 25vh;
  padding-bottom: 8px;
  overflow: hidden scroll;
`

export const StyledText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-3);
  font-variant-numeric: slashed-zero;
`

export const FirstLineSearchOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const CustomDivider = styled.div`
  width: 214px;
  height: 1px;
  background-color: var(--color-border-1);
  margin: 0 8px 8px;
`

export const Wrapper = styled.div<{ isOptions: boolean; isFocus?: boolean }>`
  ${({ isFocus }) => (isFocus ? modalShadow : 'border: 1px solid var(--color-border-1)')};
  width: 230px;
  background: ${({ isFocus }) => (!isFocus ? 'var(--color-bg-3)' : 'var(--color-bg-2)')};
  border-radius: 5px;
`

export const StyledInput = styled(InputElement)<{ isFocus: boolean }>`
  background: ${({ isFocus }) => (!isFocus ? 'none' : 'var(--color-bg-2)')};
  font-size: 14px;
  padding: 0;
  box-shadow: none;
  .ant-input {
    background: ${({ isFocus }) => (!isFocus ? 'none' : 'var(--color-bg-2)')};
  }
`
