import { TableProps } from 'antd'
import { DEFAULT_PAGE_SIZE } from 'antd/es/table/hooks/usePagination'
import { useTypedSelector } from 'app/redux/lib/selector'
import { ESortDir } from 'features/cases-management/types/TCasePagination'
import { t } from 'features/cases-management/ui/cases-table/CasesTable'
import { updateSelection } from 'features/uploaded-file/lib/common'
import { useUploadedFileQueryParams } from 'features/uploaded-file/lib/hooks'
import { setSortOrder } from 'features/uploaded-file/lib/util'
import { useUploadedFileTabContext } from 'features/uploaded-file/ui/UploadedFileTabContext'
import { FileToPreview } from 'pages/sump/lib/types'
import BarcodeContainer from 'pages/sump/ui/BarcodeContainer'
import RowActions from 'pages/sump/ui/RowActions'
import { ColumnProblemText } from 'pages/sump/ui/tables/ui/column/ColumnRender'
import { getBarcodeFailText, getFileMimeType } from 'pages/uploaded-file/lib/renderHandlers'
import { viewerPageSlice } from 'pages/viewer'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FullScreenModalElement, TextElement } from 'shared/ui/kit'
import { PdfViewer } from 'shared/ui/pdf'
import { Column, dateTimeRenderer } from 'shared/ui/table'
import { convertToUpperCaseWithUnderscore } from 'shared/ui/table/lib/helpers'
import { useCustomVt, useTableSort } from 'shared/ui/table/lib/hooks'
import { StyledTable, StyledTableWrapper } from 'shared/ui/table/ui/Table.styled'
import styled from 'styled-components/macro'
import { EUploadedFileSortBy, EUploadedFileTab, EUploadedFileType } from 'types/IUploadedFile'
import { IBarcode, IUploadedFileDTO } from 'types/IUploadedFileDTO'
import IUser from 'types/IUser'
import { VtOpts } from 'virtualizedtableforantd4/dist/esm/vt'

import { useUploadedFileContext } from './UploadedFileContext'

const DEFAULT_ORDER_SORT = 'ascend'
const DEFAULT_ORDER_FIELD = 'createdAt'

type TProps = {
  uploadedFileList: IUploadedFileDTO[]
  fetchNextPage: () => void
  hasNextPage?: boolean
  isFetching: boolean
  isLoading: boolean
}

const UploadedFileTable: FC<TProps> = ({ fetchNextPage, hasNextPage, isFetching, isLoading, uploadedFileList }) => {
  const dispatch = useDispatch()
  const { currentTab } = useUploadedFileQueryParams()
  const [fileToPreview, setFileToPreview] = useState<FileToPreview>()
  const filters = useTypedSelector((state) => state.viewerPage.sumpFilters[currentTab])
  const {
    selectedFile,
    selectedFiles,
    selectedRowIndex,
    selectedRowKeys,
    setSelectedFile,
    setSelectedFiles,
    setSelectedRowIndex,
    setSelectedRowKeys,
  } = useUploadedFileTabContext()
  const { searchString } = useUploadedFileContext()
  const isSearchingTable = !!searchString?.length
  const [selectedTypeTab, setSelectedTypeTab] = useState<EUploadedFileTab>()
  const dupBarcode = useTypedSelector((state) => state.viewerPage.sumpDuplicateBarcodeShowNumber)

  const { handleSortChange, sortConfig } = useTableSort<IUploadedFileDTO>({
    field: DEFAULT_ORDER_FIELD,
    order: DEFAULT_ORDER_SORT,
  })
  /** Изменение сортировки */
  useEffect(() => {
    const sortBy = convertToUpperCaseWithUnderscore(sortConfig.field) as EUploadedFileSortBy
    const sortDir = convertToUpperCaseWithUnderscore(sortConfig.order) as ESortDir

    if (filters?.sortBy === sortBy && filters?.sortDir === sortDir) return

    dispatch(
      viewerPageSlice.actions.setSumpFilters({
        filters: {
          ...filters,
          sortBy,
          sortDir,
        },
        key: currentTab,
      }),
    )
  }, [sortConfig])

  const onTableScroll: VtOpts['onScroll'] = ({ isEnd }) => {
    if (isEnd && !isFetching) {
      hasNextPage && fetchNextPage()
    }
  }

  const [vt] = useCustomVt({ onScroll: onTableScroll }, [isFetching])

  const onRowSelection = (selectedRowKeys: React.Key[], selectedRows: IUploadedFileDTO[]) => {
    setSelectedFiles(selectedRows)
    setSelectedRowKeys(selectedRowKeys)
    setSelectedFile(undefined)
  }

  const rowSelection: TableProps<IUploadedFileDTO>['rowSelection'] = {
    getCheckboxProps: (record: IUploadedFileDTO) => ({
      disabled: selectedTypeTab && record.uploadedFileTab !== selectedTypeTab,
    }),
    hideSelectAll: isSearchingTable,
    onChange: onRowSelection,
    onSelect: (record: IUploadedFileDTO, selected: boolean, selectedRows?: IUploadedFileDTO[]) => {
      if (selected) {
        !selectedTypeTab && setSelectedTypeTab(record.uploadedFileTab)
      } else {
        !selectedRows?.length && setSelectedTypeTab(undefined)
      }
    },
    selectedRowKeys,
    type: 'checkbox',
  }

  const handleRowClick = (data: IUploadedFileDTO, event: React.MouseEvent<HTMLTableRowElement>, index?: number) => {
    const { ctrlKey, metaKey } = event

    if (selectedFile?.uploadedFileId === data.uploadedFileId) return

    if (ctrlKey || metaKey) {
      setSelectedFiles((prevSelectedFiles) => updateSelection(prevSelectedFiles, data, selectedFile))
      setSelectedFile(undefined)
    } else {
      setSelectedFiles([])
      setSelectedRowKeys([])
      setSelectedFile(data)
      setSelectedRowIndex(index ?? null)
    }
  }

  const onRow: TableProps<IUploadedFileDTO>['onRow'] = (data, index) => ({
    'data-testid': `row-number-${index}`,
    key: index,
    onClick: (event) => handleRowClick(data, event, index),
  })

  const getRowClassName = (row: IUploadedFileDTO, index: number) => {
    const isSelected = selectedFiles.some((selectedFile) => selectedFile.uploadedFileId === row.uploadedFileId)
    return isSelected || (index === selectedRowIndex && !selectedFiles.length) ? 'selected-row' : ''
  }

  useEffect(() => {
    if (!selectedRowKeys.length && selectedTypeTab) {
      setSelectedTypeTab(undefined)
    }
  }, [selectedRowKeys])

  const isCaseNameVisible = isSearchingTable || EUploadedFileTab.ATTACHED_TO_CASE === currentTab
  // Колонки время переноса и перенесено
  const isDeletedInfoVisible = currentTab === EUploadedFileTab.DELETED && !isSearchingTable
  const isStateVisible = !isSearchingTable && !dupBarcode.length

  const onHeaderCell = (columnName: string) =>
    ({
      'data-testid': `${columnName.toLowerCase()}-column-header`,
    } as React.HTMLProps<HTMLTableCellElement>)

  return (
    <StyledTableWrapper>
      <StyledTable<FC<TableProps<IUploadedFileDTO>>>
        scroll={{ y: '100%' }}
        tableLayout="fixed"
        components={vt}
        rowClassName={getRowClassName}
        rowSelection={rowSelection}
        dataSource={uploadedFileList}
        loading={isLoading}
        pagination={false}
        showSorterTooltip={false}
        onChange={handleSortChange}
        rowKey={({ uploadedFileId }, index) => {
          const pageNumber = Math.floor((index || 0) / DEFAULT_PAGE_SIZE)
          return `${pageNumber}-${index}-${uploadedFileId}`
        }}
        onRow={onRow}
      >
        <Column<IUploadedFileDTO>
          className="has-divider"
          title={t('Имя файла')}
          data-testid="fileName"
          dataIndex="fileName"
          key="fileName"
          onHeaderCell={() => onHeaderCell('fileName')}
          render={(value, record: IUploadedFileDTO, index) => {
            const rowActions = <RowActions labelUrl={record.labelUrl} record={record} />
            if (record.fileType === EUploadedFileType.DOCUMENT) {
              return (
                <FileItem
                  onClick={() => {
                    const mimeType = getFileMimeType(record.fileName)
                    if (record.documentUrl) setFileToPreview({ mimeType, url: record.documentUrl })
                  }}
                >
                  <TextElement
                    data-testid={`filename-cell-${index}`}
                    style={{ color: 'var(--color-purple-light)' }}
                    ellipsis
                  >
                    {value}
                  </TextElement>
                </FileItem>
              )
            }
            return (
              <div style={{ alignItems: 'center', display: 'flex', gap: 8, justifyContent: 'space-between' }}>
                <TextElement data-testid={`filename-cell-${index}`} ellipsis>
                  {value}
                </TextElement>
                {rowActions}
              </div>
            )
          }}
        />
        {isCaseNameVisible && (
          <Column
            className="has-divider"
            key="caseName"
            dataIndex="caseName"
            onHeaderCell={() => onHeaderCell('caseName')}
            title={t('Имя случая')}
          />
        )}
        <Column
          className="has-divider"
          title={t('Время загрузки')}
          dataIndex="createdAt"
          key="createdAt"
          render={dateTimeRenderer}
          sorter={true}
          sortOrder={setSortOrder(filters, currentTab, EUploadedFileSortBy.CREATED_AT)}
          onHeaderCell={() => onHeaderCell('createdAt')}
        />
        <Column
          className="has-divider"
          dataIndex="path"
          key="path"
          title={t('Путь')}
          render={(path: string) => <TextElement ellipsis>{path}</TextElement>}
          sorter={true}
          sortOrder={setSortOrder(filters, currentTab, EUploadedFileSortBy.PATH)}
          onHeaderCell={() => onHeaderCell('path')}
        />
        {isDeletedInfoVisible && (
          <Column
            className="has-divider"
            title={t('Время переноса')}
            dataIndex="deletedAt"
            key="deletedAt"
            render={dateTimeRenderer}
            sorter={true}
            sortOrder={setSortOrder(filters, currentTab, EUploadedFileSortBy.DELETED_AT)}
            onHeaderCell={() => onHeaderCell('deletedAt')}
          />
        )}
        {isDeletedInfoVisible && (
          <Column
            className="has-divider"
            title={t('Перенесено')}
            dataIndex="deletedByUser"
            key="deletedByUser"
            render={(user: IUser) => <User user={user} />}
            sorter={true}
            sortOrder={setSortOrder(filters, currentTab, EUploadedFileSortBy.DELETED_BY_USER)}
            onHeaderCell={() => onHeaderCell('deletedByUser')}
          />
        )}
        <Column
          className="has-divider"
          dataIndex="barcodes"
          key="barcodes"
          title={t('Штрихкод')}
          render={(barcodes: IBarcode[]) =>
            barcodes ? barcodes.map((item) => <BarcodeContainer key={item.barcode} barcode={item} />) : '-'
          }
          onHeaderCell={() => onHeaderCell('barcodes')}
        />
        {isStateVisible && (
          <Column
            title={t('Проблема')}
            dataIndex="state"
            key="state"
            sorter={true}
            sortOrder={sortConfig.field === 'state' ? sortConfig.order : undefined}
            render={(
              barcodeFailureReason,
              {
                conversionFailureCodeDzi,
                conversionFailureCodeTiff,
                duplicateOfSlide,
                duplicatesCount,
                state,
              }: IUploadedFileDTO,
            ) => (
              <ColumnProblemText
                conversionFailureCodeDzi={conversionFailureCodeDzi}
                conversionFailureCodeTiff={conversionFailureCodeTiff}
                duplicateOfSlide={duplicateOfSlide}
                duplicatesCount={duplicatesCount}
                state={state}
              />
            )}
            onHeaderCell={() => onHeaderCell('state')}
          />
        )}
        {isSearchingTable && (
          <Column
            title={t('Статус')}
            dataIndex="status"
            key="status"
            width={200}
            showSorterTooltip={false}
            render={(barcodeFailureReason, record: IUploadedFileDTO) => {
              switch (record.uploadedFileTab) {
                case 'AWAITING_CASE':
                  return t(`Ожидает`)
                case 'NOT_ATTACHED_TO_CASE':
                  return `${t('Ошибки')}: ${getBarcodeFailText(record.state)}`
                case 'ATTACHED_TO_CASE':
                  return `${t('Разобрано')}: ${record?.attachedToCaseByUser?.fullname || t('Автоматически')}`
                case 'DELETED':
                  return t('Корзина')
                case 'DUPLICATE':
                  return t('Дубль')
              }
            }}
            onHeaderCell={() => onHeaderCell('status')}
          />
        )}
      </StyledTable>
      <FullScreenModalElement
        visible={Boolean(fileToPreview)}
        onCancel={() => setFileToPreview(undefined)}
        footer={null}
      >
        {fileToPreview?.mimeType === 'application/pdf' ? (
          <PdfViewer file={fileToPreview?.url} />
        ) : (
          <div style={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'center' }}>
            <img src={fileToPreview?.url} style={{ maxHeight: '100%', maxWidth: '100%' }} alt="file-preview" />
          </div>
        )}
      </FullScreenModalElement>
    </StyledTableWrapper>
  )
}

export default UploadedFileTable

const FileItem = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-start;
  cursor: pointer;
  gap: 8px;

  & > svg {
    color: white;
    flex-shrink: 0;
  }
`

const User: React.FC<{ user: IUser | null }> = ({ user }) => {
  const { t } = useTranslation()
  return <TextElement ellipsis>{user?.fullname ?? t('Автоматически')}</TextElement>
}
