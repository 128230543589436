import stubLabel from 'assets/thumbnails/stub-label.png'
import {
  MacroFileProps,
  MicroFileProps,
  updateSelection,
  UploadedFileProps,
  ViewType,
} from 'features/uploaded-file/lib/common'
import { useUploadedFileContext } from 'features/uploaded-file/ui/table/UploadedFileContext'
import { useUploadedFileTabContext } from 'features/uploaded-file/ui/UploadedFileTabContext'
import React, { FC, memo } from 'react'
import { IconElement } from 'shared/ui/kit'
import { EUploadedFileType } from 'types/IUploadedFile'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

import { StyledContainer, StyledImage, StyledImageWrapper } from './UploadedFilePreviewImage.styles'

const UploadedFilePreviewImage = memo(
  ({ file, handleContextMenu, rotateLabel, selectedFile, viewType }: UploadedFileProps) => {
    const { isTableMode, tileSize } = useUploadedFileContext()
    const {
      selectedFile: selectedFileContext,
      selectedFiles,
      setSelectedFile,
      setSelectedFiles,
      setSelectedRowKeys,
    } = useUploadedFileTabContext()

    const currentFile = file || selectedFile

    const handleClick = (data: IUploadedFileDTO, event: React.MouseEvent<HTMLTableRowElement>) => {
      const { ctrlKey, metaKey } = event

      if (selectedFileContext?.uploadedFileId === data.uploadedFileId) return setSelectedFile(undefined)

      if (ctrlKey || metaKey) {
        setSelectedFiles((prevSelectedFiles) => updateSelection(prevSelectedFiles, data, selectedFileContext))
        setSelectedFile(undefined)
      } else {
        setSelectedFiles([])
        setSelectedRowKeys([])
        setSelectedFile(data)
      }
    }

    const renderFilePreview = () => {
      switch (currentFile?.fileType) {
        case EUploadedFileType.MACRO:
          return (
            <MacroFile
              onClick={(e: React.MouseEvent<HTMLTableRowElement>) => file && !isTableMode && handleClick(file, e)}
              tileSize={tileSize}
              viewType={viewType}
              src={currentFile.thumbnails.medium}
            />
          )
        case EUploadedFileType.DOCUMENT:
          return (
            <DocumentFile
              onClick={(e: React.MouseEvent<HTMLTableRowElement>) => file && !isTableMode && handleClick(file, e)}
            />
          )
        case EUploadedFileType.MICRO:
          return (
            <MicroFile
              onClick={(e: React.MouseEvent<HTMLTableRowElement>) => file && !isTableMode && handleClick(file, e)}
              tileSize={tileSize}
              viewType={viewType}
              src={currentFile.thumbnails.medium}
              rotateLabel={rotateLabel}
              labelUrl={currentFile.labelUrl}
            />
          )
        default:
          return null
      }
    }

    const isSelected = !!(
      (viewType === ViewType.GRID && selectedFileContext?.uploadedFileId === file?.uploadedFileId) ||
      (file && selectedFiles.includes(file))
    )

    return (
      <StyledContainer
        isSelected={isSelected}
        tileSize={tileSize}
        viewType={viewType}
        onContextMenu={handleContextMenu}
      >
        {renderFilePreview()}
      </StyledContainer>
    )
  },
)

const MacroFile: FC<MacroFileProps> = ({ src, tileSize, viewType }) => (
  <StyledImage tileSize={tileSize} viewType={viewType} src={src} />
)

const DocumentFile: FC<{ onClick: (e: React.MouseEvent<HTMLTableRowElement>) => void }> = ({ onClick }) => (
  <div onClick={onClick}>
    <IconElement name="pdfPreview" style={{ height: '100%', width: '100%' }} />
  </div>
)

const MicroFile: FC<MicroFileProps> = ({ labelUrl, onClick, rotateLabel, src, tileSize, viewType }) => (
  <StyledImageWrapper onClick={onClick} tileSize={tileSize} viewType={viewType}>
    <StyledImage tileSize={tileSize} viewType={viewType} src={src} />
    <StyledImage
      tileSize={tileSize}
      viewType={viewType}
      style={{ transform: rotateLabel && labelUrl ? `rotate(${rotateLabel}deg)` : 'none' }}
      src={labelUrl || stubLabel}
    />
  </StyledImageWrapper>
)

export default UploadedFilePreviewImage
