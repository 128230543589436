import { Slider } from 'antd'
import { useUploadedFileContext } from 'features/uploaded-file/ui/table/UploadedFileContext'
import React from 'react'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components'

const MIN_TILE_SIZE = 160
const MAX_TILE_SIZE = 320

const UploadedFileSlider = () => {
  const { setTileSize, tileSize } = useUploadedFileContext()

  const handleSliderChange = (value: number) => setTileSize(value)

  return (
    <Container>
      <IconElement name="smallTiles" />
      <StyledSlider min={MIN_TILE_SIZE} max={MAX_TILE_SIZE} defaultValue={tileSize} onChange={handleSliderChange} />
      <IconElement name="bigTiles" />
    </Container>
  )
}

export default UploadedFileSlider

const Container = styled.div`
  width: 128px;
  height: 32px;
  border-radius: 16px;
  padding: 8px;

  display: flex;
  gap: 4px;
  align-items: center;
  background: var(--color-border-1);
`

const StyledSlider = styled(Slider)`
  width: 72px;
  height: 8px;

  .ant-slider-handle {
    background-color: var(--color-white);
    border: none;
    margin-top: -3px;
    width: 8px;
    height: 8px;
  }

  .ant-slider-rail,
  .ant-slider-track,
  .ant-slider-step {
    height: 2px;
  }
`
